import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'
import { runMockingServer } from './mock-server'

if (process.env.VUE_APP_USE_MOCKING == 'true') {
  runMockingServer()
}

Vue.prototype.$warn = (...error) => {
  console.warn('[TaiwanGenomes]', ...error)
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
