import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import annotaionGroup from '../annotaion_group.json'
import annotaionGroupSup from '../annotaion_group_sup.json'

Vue.use(Vuex)

const _parseColumns = groupTree => {
  // annotaionGroup (groupTRee)
  //   Functional (group)
  // 	   refGene (groupNode)
  // 		Func.refGene
  // 		Gene.refGene
  // 	   avsnp150 (columnNode)
  const returnObjs = { columnGroups: {}, columnNames: [], columns: {} }

  for (const group in groupTree) {
    returnObjs.columnGroups[group] = []
    for (const nodeKey in groupTree[group]) {
      const node = groupTree[group][nodeKey]
      returnObjs.columnGroups[nodeKey] = []
      // if node has a name, it is a columnNode
      if (node.name) {
        if (node.visible !== true) continue
        node.group = group
        returnObjs.columnGroups[group].push(node.name)
        returnObjs.columnNames.push(node.name)
        returnObjs.columns[node.name] = node
        // if node has no name, it is a groupNode
      } else {
        for (const columnKey in node) {
          const column = groupTree[group][nodeKey][columnKey]
          if (column.visible !== true) continue
          column.group = nodeKey
          returnObjs.columnGroups[nodeKey].push(column.name)
          returnObjs.columnNames.push(column.name)
          returnObjs.columns[column.name] = column
        }
      }
    }
  }
  returnObjs.filters = _resetFilters(returnObjs.columns)
  return returnObjs
}

const _renameColumns = (columns, suffix) =>
  Object.fromEntries(Object.entries(columns).map(([k, v]) => [k + suffix, v]))

const _resetFilter = column => {
  const filter = { filtered: false }

  if (column.position) filter.position = []
  if (column.contain) filter.contain = []

  if (column.equality) {
    filter.equality = column.equality
    filter.threshold = column.threshold || 0
  }

  if (column.equalTo) {
    filter.equalTo = column.equalTo.map(obj => {
      return { ...obj, checked: true }
    })
  }
  return filter
}

const _resetFilters = columns => {
  const filters = { '': {} }
  for (const column of Object.values(columns)) {
    const filter = _resetFilter(column)
    filters[column.name] = filter
  }
  return filters
}

const getStateByRoute = (state, origin) => {
  if (state.currentRoute == '/supplement') return state[origin + 'Sup']
  return state[origin + 'Main']
}

export default new Vuex.Store({
  actions,

  getters: {
    filtered: (state, getters) => {
      if (getters.cohort && getters.cohort.info)
        return JSON.parse(getters.cohort.info).filtered || []
      return []
    },
    filterDetails: (state, getters) => {
      const detail = []
      for (const k in getters.filters) {
        const filter = getters.filters[k]

        if (!filter.filtered) continue
        if (filter.equality && filter.equality != '?')
          detail.push({
            name: k,
            detail: `${k} ${filter.equality} ${filter.threshold}`
          })

        if (filter.equalTo) {
          //! duplicate code
          const values = filter.equalTo
            .filter(v => v['checked'])
            .map(v => v['display'] ?? v['query'])
          if (values.length && values.length < filter.equalTo.length)
            detail.push({
              name: k,
              detail: `${k}: ${values.join(', ')}`
            })
        }

        if (filter.contain && filter.contain.length) {
          detail.push({
            name: k,
            detail: `${k}: ${filter.contain.join(', ')}`
          })
        }
        if (filter.position && filter.position.length) {
          detail.push({
            name: k,
            detail: `${k}: ${filter.position.join(', ')}`
          })
        }
      }
      return detail
    },
    selectedColumns: (state, getters) => {
      return Object.fromEntries(
        Object.entries(getters.columns).filter(([key, value]) => value.selected)
      )
    },
    loggedIn: state => state.user.cohorts != undefined,

    nRequests: state => Object.keys(state.requests).length,

    nVariants: (state, getters) =>
      getters.cohort ? getters.cohort.n_variants : 0,

    getInfoById: state => id => JSON.parse(state.user.cohorts[id].info),

    cohort: state => {
      try {
        return state.user.cohorts[state.selectedCohortIdx]
      } catch {
        return
      }
    },
    sampleBamFiles: state => {
      if (state.selectedCohortIdx == null) return {}
      let cohort = state.user.cohorts[state.selectedCohortIdx]
      let info = JSON.parse(cohort.info)
      let bamFiles = info['bam'].split(',')

      let samples = cohort.samples
      samples = samples.split(',')
      if (samples.length < 0) {
        return {}
      }

      let sampleBamFiles = {}
      for (let i in samples) {
        for (let file of bamFiles) {
          if (file != '' && samples[i] != '' && file.search(samples[i]) != -1) {
            sampleBamFiles[samples[i]] = file
          }
        }
      }

      return sampleBamFiles
    },

    currentPanel: state => getStateByRoute(state, 'currentPanel'),
    columnGroups: state => getStateByRoute(state, 'columnGroups'),
    columns: state => getStateByRoute(state, 'columns'),
    filters: state => getStateByRoute(state, 'filters'),
    stagedFilters: state => getStateByRoute(state, 'stagedFilters')
  },

  mutations: {
    addRequest: (state, { k, v }) => Vue.set(state.requests, k, v),
    delRequest: (state, k) => Vue.delete(state.requests, k),
    resetRequest: state => (state.requests = {}),
    resetFilter: (state, columnName) => {
      Vue.set(
        getStateByRoute(state, 'filters'),
        columnName,
        _resetFilter(getStateByRoute(state, 'columns')[columnName])
      )
    },
    resetFilters: state => {
      if (state.currentRoute === '/supplement') {
        Vue.set(state, 'filtersSup', _resetFilters(state.columnsSup))
      } else {
        Vue.set(state, 'filtersMain', _resetFilters(state.columnsMain))
      }
    },
    resetStagedFilters: state => {
      if (state.currentRoute === '/supplement') {
        state.stagedFiltersSup = ['Add a column']
      } else {
        state.stagedFiltersMain = ['Add a column']
      }
    },
    resetStagedFilter: (state, { idx, filterList }) => {
      let filterName = getStateByRoute(state, 'stagedFilters')[idx]

      let filterReset = getStateByRoute(state, 'filters')[filterName]
      if (filterReset.contain) {
        Vue.set(filterReset, 'contain', [])
      } else if (filterReset.position) {
        Vue.set(filterReset, 'position', [])
      } else if (filterReset.equality) {
        filterReset.equality = '?'
        filterReset.threshold = 100
      } else if (filterReset.equalTo) {
        for (let row of filterReset.equalTo) row['checked'] = true
      }
      filterReset.filtered = false

      getStateByRoute(state, 'stagedFilters').splice(
        idx,
        1,
        `${filterName} has been reset`
      )
    },
    setCurrentRoute: (state, route) => {
      state.currentRoute = route || null
    },
    setCurrentpanel: (state, idx) => {
      if (state.currentRoute == '/supplement')
        state.currentPanelSup = state.panels[idx] || null
      else state.currentPanelMain = state.panels[idx] || null
    },
    setStagedFilters: (state, { idx, column }) => {
      let stagedFilters = getStateByRoute(state, 'stagedFilters')

      if (stagedFilters.includes(column)) return

      Vue.set(stagedFilters, idx, column)
      if (idx == stagedFilters.length - 1) {
        Vue.set(stagedFilters, idx + 1, 'Add a column')
      }

      const resetIdx = stagedFilters.indexOf(`${column} has been reset`)
      if (resetIdx < 0) return
      stagedFilters.splice(resetIdx, 1)
    },
    selectFilterColumn: (state, { name, selected }) => {
      getStateByRoute(state, 'columns')[name].selected = selected
    },
    setCohortNLoaded: (state, { idx, nLoaded }) => {
      Vue.set(state.user.cohorts[idx], 'n_loaded_variants', nLoaded)
    },
    setCohortNVariants: (state, { idx, nVariants }) => {
      Vue.set(state.user.cohorts[idx], 'n_variants', nVariants)
    },
    setCohortInitialized: (state, { isInitialized }) => {
      state.isCohortInitialized = isInitialized
    },

    setCohortSamples: (state, { samples }) => {
      return
    },

    setSelectedCohortIdx: (state, id) => {
      state.selectedCohortIdx = id
    },

    setCohortPid: (state, { idx, pid }) => {
      state.user.cohorts[idx].pid = pid
    },

    setCohortFilters: (state, { queries }) => {
      if (!state.user.cohorts) return
      Vue.set(state.user.cohorts, state.selectedCohortIdx, {
        ...state.user.cohorts[state.selectedCohortIdx],
        n_variants: undefined,
        queries
      })
    },

    setCohortSubpanel: (state, v) => (state.cohortSubpanel = v),

    setLayout: (state, v) => (state.layout = v),

    setLoginError: (state, v) => (state.loginError = v),

    setMainCreated: (state, v) => (state.isMainCreated = v),

    setFriends: (state, friends) => {
      Vue.set(state, 'friends', [...friends])
    },

    setTimeID: (state, id) => {
      state.timeID = id
    },

    setUser: (state, v) => (state.user = v),

    setUserCohort: (state, { idx, update_obj }) => {
      const cohorts = state.user.cohorts
      cohorts[idx] = { ...cohorts?.[idx], ...update_obj }
      Vue.set(state.user, 'cohorts', [...cohorts])
    }
  },

  state: {
    ..._renameColumns(_parseColumns(annotaionGroup), 'Main'), // columnGroupsMain, columnNamesMain, columnsMain, filtersMain
    ..._renameColumns(_parseColumns(annotaionGroupSup), 'Sup'), // columnGroupsSup, columnNamesSup, columnsSup, filtersSup
    currentRoute: '',
    panels: ['column', 'filter'],
    currentPanelMain: 'filter',
    currentPanelSup: 'filter',
    activeColumnName: 'Select a Filter',
    cohortSubpanel: 'list',
    filterSubpanel: 'editor',
    layout: 'vertical',
    loginError: '',
    requests: {},
    selectedCohortIdx: null,
    stagedFiltersMain: ['Add a column'],
    stagedFiltersSup: ['Add a column'],
    timeID: null,
    isCohortInitialized: false,
    user: {},
    friends: [],
    isMainCreated: false
  }
})
