import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    { path: '/', component: () => import('@/views/Home') },
    { path: '/faq', component: () => import('@/views/Faq') },
    { path: '/info', component: () => import('@/views/Info') },
    { path: '/license', component: () => import('@/views/License') },
    { path: '/main', component: () => import('@/views/Main') },
    { path: '/news', component: () => import('@/views/News') },
    { path: '/supplement', component: () => import('@/views/Supplement') }
  ]
})
