import { render, staticRenderFns } from "./App.vue?vue&type=template&id=45dad25f&scoped=true&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./App.vue?vue&type=style&index=1&id=45dad25f&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45dad25f",
  null
  
)

export default component.exports