<template lang="pug">
#v-app
  .-header
    span(@click='navBarOpened = false')
      router-link(to='/') TaiwanGenomes
    .-nav-bar(:class="{ open: navBarOpened }")
      .-nav-links
        h3(v-for="(route, key) in routes" :class="{ active: route === $route.path }" @click='navBarOpened = false')
          router-link(:to='route') {{ key.toUpperCase() }}
    .-humburger(@click='navBarOpened = !navBarOpened')
      .-line
      .-line
      .-line
  keep-alive: router-view.-body

</template>

<script>
import 'semantic-ui-offline/semantic.css'
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    navBarOpened: false,
    routes: {
      // the key order is important
      main: '/main',
      supplement: '/supplement',
      info: '/info',
      license: '/license',
      faq: '/faq'
      // news: '/news'
    }
  }),
  watch: {
    $route(to, from) {
      this.setCurrentRoute(to.path)
    }
  },
  methods: {
    ...mapMutations(['setCurrentRoute'])
  }
}
</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box
</style>

<style lang="sass" scoped>
@import "./assets/variables.sass"

$header-bottom-border: 2px solid rgba(34, 36, 38, 0.15)
$title-color: $LIGHT-COLOR-3
$header-color: $DARK-COLOR-2

#v-app
  background-color: $LIGHT-COLOR-5
  min-height: 100vh

a
  color: $title-color

.-header
  background-color: $header-color
  border-bottom: $header-bottom-border
  height: $HEADER-HEIGHT
  line-height: $HEADER-HEIGHT
  position: fixed
  width: 100%
  z-index: 999
  span
    font-size: 1.75em
    margin: 1em


.-nav-bar
  background-color: $header-color
  display: inline-block
  vertical-align: top

.-nav-links
  color: $title-color
  display: flex
  font-size: 1.5em
  justify-content: space-around
  padding: 0 .7rem
  h3
    height: $HEADER-HEIGHT
    line-height: $HEADER-HEIGHT
    margin: 0em 1em
    &.active
      border-bottom-color: $title-color
      border-bottom-style: solid
      border-bottom-width: .5em

.-body
  padding-top: $HEADER-HEIGHT

@media screen and (max-width: 768px)
  .-nav-bar
    clip-path: inset(0 0 100% 0)
    position: fixed
    margin-top: $HEADER-HEIGHT
    top: 0
    right: 0
    width: 100vw
    height: 60vh
    transition: clip-path .6s ease-out
    pointer-events: none

  .-nav-bar.open
    clip-path: inset(0 0 0 0)
    pointer-events: all

  .-nav-links
    width: 100%
    height: 100%
    flex-direction: column
    align-items: center

  .-humburger
    position: absolute
    cursor: pointer
    right: 6%
    top: 50%
    transform: translate(-5%, -50%)
    background-color: $DARK-COLOR-4
    padding: 5px
    border-radius: 6px
    z-index: 2

  .-line
    width: 22px
    height: 2px
    background-color: $title-color
    margin: 5px

  @for $i from 1 through 6
    .-nav-links h3:nth-child(#{$i})
      transition: opacity 0.6s ease 0.1s*$i

  .-nav-bar
    h3
     opacity: 0

  .-nav-bar.open
    h3
     opacity: 1
</style>
